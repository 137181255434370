import SyncLoader from "react-spinners/SyncLoader";


class Loader {
    render(text: string) {
        return (
            <div className="loading">
                <div className="loading-message">{text}</div>
                <div className="loading-widget">
                    <SyncLoader size="10" color="#5cd5eb"/>
                </div>
            </div>
        )
    }
}

export default Loader
