import React from 'react';


class EnvironmentVariableProvider {
    constructor() {
    }

    getDeploymentEnvironment() {
        return process.env.REACT_APP_DEPLOYMENT_ENV
    }
}

export default EnvironmentVariableProvider
