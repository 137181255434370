import EnvironmentVariableProvider from "src/config/EnvironmentVariableProvider"


class DeploymentEnvironmentConfigOperator {
    static VALID_DEPLOYMENT_ENVIRONMENTS: any = new Set(["PROD", "PREPROD", "DEV"])
    deploymentEnvironment: string
    defaultConfig: any
    deploymentEnvironmentConfig: any
    environmentVariableProvider: EnvironmentVariableProvider

    constructor(deploymentEnvironmentConfig: any, environmentVariableProvider: EnvironmentVariableProvider) {
        this.environmentVariableProvider = environmentVariableProvider
        this.deploymentEnvironment = this.getDeploymentEnvironment()
        this.defaultConfig = deploymentEnvironmentConfig["DEFAULT"]
        this.deploymentEnvironmentConfig = deploymentEnvironmentConfig[this.deploymentEnvironment]
    }

    getDeploymentEnvironment() {
        let deploymentEnvironment = this.environmentVariableProvider.getDeploymentEnvironment()
        if (deploymentEnvironment && DeploymentEnvironmentConfigOperator.VALID_DEPLOYMENT_ENVIRONMENTS.has(deploymentEnvironment)) {
            return deploymentEnvironment
        }
        throw Error("No deployment environment specified")
    }

    get(key: string) {
        if (key in this.deploymentEnvironmentConfig) {
            return this.deploymentEnvironmentConfig[key]
        }
        if (key in this.defaultConfig) {
            return this.defaultConfig[key]
        }
        throw Error(`The key: ${key} does not exist in the deployment configuration`)
    }
}

export default DeploymentEnvironmentConfigOperator
