import StateSavingChildComponent from "src/components/base/StateSavingChildComponent";
import ToggleButton from "src/components/custom/ToggleButton";


class MainUpdateForm extends StateSavingChildComponent {
    render() {
        return (
            <fieldset className="main-form container">
                <button className="partition field update-valuation-button" onClick={this.valuate}>Update</button>
                <button className="partition field update-valuation-button" onClick={this.newValuation}>New</button>
                <ToggleButton className="partition toggle" parent={this.parent} toggleField={"advancedOptions"}/>
            </fieldset>
        )
    }
}

export default MainUpdateForm
