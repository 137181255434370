import {Component} from "react"
import Loader from "src/components/general/Loading"
import LoginHelper from "src/components/login/LoginHelper"


class LoginPage extends Component<any> {
    loginHelper: LoginHelper
    loader: Loader

    constructor(props: any) {
        super(props);
        this.loginHelper = props.loginHelper
        this.loader = props.loader
    }

    componentDidMount() {
        this.loginHelper.login()
    }

    render() {
        return this.loader.render("Logging in")
    }
}

export default LoginPage
