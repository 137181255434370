import {Component} from "react"


class ValuationDescription extends Component<any> {
    render() {
        return (
            <div className="valuation-description major-mono-display-regular"> 
                ireland's house Valuation tool
                <br></br>
                <br></br>
                Valuate your home
            </div>
        )
    }
}

export default ValuationDescription
