import StateSavingChildComponent from "src/components/base/StateSavingChildComponent";
import BellCurveSlider from "src/components/custom/bell_curve/BellCurveSlider"


class HouseConditionForm extends StateSavingChildComponent {
    render() {
        return (
            <fieldset>
                <div className="container house-condition">
                    <div className="partition">
                        <BellCurveSlider parent={this.parent} sliderValue="current-house-condition" sliderLabel="House Condition"/>
                    </div>
                </div>
            </fieldset>
        )
    }
}

export default HouseConditionForm
