import QuotaLimitExceededError from "src/validation/exception_messages/QuotaLimitExceededError";
import ServerError from "src/validation/exception_messages/ServerError";
import ThrottleLimitExceededError from "src/validation/exception_messages/ThrottleLimitExceededError";
import UserInputError from "src/validation/exception_messages/UserInputError";


class ErrorHandler {
    render(error: any) {
        let message = "Oh no! There's been an error."
        if (error instanceof UserInputError) {
            message = "Hold on a minute! There was an error with the inputs you supplied"
        }
        if (error instanceof ThrottleLimitExceededError) {
            message = "Wow! Slow down there partner you've made a lot of requests in a short span of time. Wait the allotted time and try again."
        }
        if (error instanceof QuotaLimitExceededError) {
            message = "Damn! You've reached your limit for today, come back again tomorrow or consider going premium if you want to make more requests."
        }
        if (error instanceof ServerError) {
            message = "Yikes! We seem to be having some issues with our servers. Try again in a few minutes."
        }
        return (
            <div className="error-container" id="user-visible-error-field" data-error-type={typeof(error)}> 
                <div className="error-warning" id="user-visible-error-warning-field">{message}</div>
                <div className="error-message" id="user-visible-error-message-field">[ {error.message} ]</div>
            </div>
        )
    }
}

export default ErrorHandler
