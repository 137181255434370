import StateSavingChildComponent from "src/components/base/StateSavingChildComponent";
import HouseValuationInputsForm from "src/components/house_valuation/house_valuation_inputs/forms/HouseValuationInputsForm"


class HouseValuationInputsPage extends StateSavingChildComponent {
    render() {
        return (
            <HouseValuationInputsForm parent={this.parent}/>
        )
    }
}

export default HouseValuationInputsPage
