import sha256 from 'crypto-js/sha256';


class Hasher {
    static hash(input: object) {
        let inputString = JSON.stringify(input);
        return sha256(inputString).toString()
    }

    static deepCopy(input: any) {
        return JSON.parse(JSON.stringify(input));
    }
}

export default Hasher
