import StateSavingComponent from "src/components/base/StateSavingComponent";
import Hasher from "src/utils/Hasher";

class ValuationStateHelper {

    static getComparableHomesInputField(component: StateSavingComponent) {
        return {
            "address": component.getState("street-address"),
            "country": component.getState("country"),
            "county": component.getState("county"),
            "town": component.getState("town")
        }
    }

    static getValuationInputField(component: StateSavingComponent) {
        let comparableHomes: any[] = []
        comparableHomes = ValuationStateHelper.getComparableHomesForValuation(component);
        let currentHouseCondition = component.getState("current-house-condition")
        return {
            "comparison_valuations": comparableHomes,
            "asset": {
                "current_state": parseFloat(currentHouseCondition) / 100,
                "future_state": parseFloat(currentHouseCondition) / 100
            }
        }
    }

    static getComparableHomesForValuation(component: StateSavingComponent) {
        let comparableHomesWidget = component.getState("comparableHomes")
        let comparableHomes = []
        for (let comparableHomeWidget of Object.values(comparableHomesWidget)) {
            let comparableHome = ValuationStateHelper.convertDictToComparableHome(comparableHomeWidget)
            comparableHomes.push(comparableHome)     
        }
        return comparableHomes
    }

    static getComparableHomes(component: any) {
        let comparableHomes = component.getState("comparableHomes");
        return Object.values(comparableHomes)
    }

    static setComparableHomes(component: any, comparableHomes: any) {
        component.assignState("comparableHomes", {})
        let comparableHomesCopy = JSON.parse(JSON.stringify(comparableHomes));
        for (let comparableHome of comparableHomesCopy) {
            ValuationStateHelper.updateComparableHome(component, Hasher.hash(comparableHome), comparableHome)
        }
    }

    static updateComparableHome(component: any, id: string, comparableHome: any) {
        let updatedComparableHomes = component.getState("comparableHomes")
        updatedComparableHomes[id] = comparableHome
        component.assignState("comparableHomes", updatedComparableHomes)
    }

    static updateComparableHomeField(component: any, id: string, key: string, value: any) {
        let updatedComparableHomes = component.getState("comparableHomes")
        updatedComparableHomes[id][key] = value
        component.assignState("comparableHomes", updatedComparableHomes)
    }

    static removeComparableHome(component: any, id: string) {
        let updatedComparableHomes = component.getState("comparableHomes")
        delete updatedComparableHomes[id]
        component.assignState("comparableHomes", updatedComparableHomes)
    }

    static convertDictToComparableHome(dict: any) {
            let comparableHome: any = {
                "value": dict["value"]
            }
            if ("state" in dict) {
                comparableHome["state"] = dict["state"]
            }
            return comparableHome
    }
}

export default ValuationStateHelper
