class Format {
    static getPosition(position: number) {
        return position + "px"

    }

    static getPercentage(position: number) {
        return position + "%"
    }

    static getFormattedCurrency(cashAmount: number, symbol: string = "€") {
        const formatter = new Intl.NumberFormat('en-US');
        return symbol + formatter.format(cashAmount);
    }
}

export default Format
