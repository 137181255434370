import {Component} from "react"
import Logo from "src/components/custom/Logo"
import WebsiteName from "src/components/layout/WebsiteName"
import ValuationDescription from "src/components/layout/ValuationDescription"

class Header extends Component<any> {
    render() {
        return (
            <div>
                <div id="header" className="header">
                    <div className="website-and-logo-container">
                        <WebsiteName/>
                        <Logo/>
                    </div>
                    <div>
                        <ValuationDescription/>
                    </div>
                </div>
            </div>
        )
    }
}

export default Header
