class BellCurve {
    std: number
    mean: number

    constructor(mean: number, standardDeviation: number) {
        this.std = standardDeviation;
        this.mean = mean
    }

    computeValue(x: number) {
        return 1/(this.std * Math.sqrt(2 * Math.PI)) * Math.exp(-Math.pow(x - this.mean, 2)/(2 * Math.pow(this.std, 2)))
    }
}

export default BellCurve
