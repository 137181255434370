import APIRequester from "src/api/APIRequester"
import LoginHelper from "src/components/login/LoginHelper"
import RedirectToLoginException from "src/validation/exception_messages/RedirectToLoginException"
import AuthenticationError from "src/validation/exception_messages/AuthenticationError"
import StateHandler from "src/components/login/StateHandler"


class ValuationAPIHelper {
    baseURL: string 
    comparableHomesURL: string 
    valuateURL: string 
    apiRequester: APIRequester
    loginHelper: LoginHelper
    stateHandler: StateHandler

    constructor(apiRequester: APIRequester, baseURL: string, loginHelper: LoginHelper, stateHandler: StateHandler) {
        this.apiRequester = apiRequester
        this.baseURL = baseURL
        this.comparableHomesURL = this.baseURL + "/comparable_homes"
        this.valuateURL = this.baseURL + "/valuate"
        this.loginHelper = loginHelper;
        this.stateHandler = stateHandler;
    }

    async getComparableHomes(input: any) {
        return await this.postOrLogin(this.comparableHomesURL, input)
    }

    async getValuation(input: any)  {
        return await this.postOrLogin(this.valuateURL, input)
    }

    async postOrLogin(url: string, input: any) {
        let response 
        try {
            let tokenHeader = await this.getTokenHeaderOrLogin()
            response = await this.apiRequester.post(url, input, tokenHeader)
        } catch(error) {
            if (error instanceof AuthenticationError) {
                this.loginHelper.logout()
                this.stateHandler.storeStatesPreLogin()
                this.loginHelper.redirectToLoginSite()
                throw new RedirectToLoginException("User is not authenticated, redirecting to login.")
            } else {
                throw error
            }
        }
        return response
    }

    async getTokenHeaderOrLogin() {
        let token: any = await this.loginHelper.getValidToken()
        if (token) {
            return {
                "x-auth-token": token
            }
        }
        throw new AuthenticationError("User is not logged in, no valid authentication token.")
    }
}

export default ValuationAPIHelper
