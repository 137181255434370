import Clock from 'src/general/Clock';
import CookieHandler from 'src/components/login/CookieHandler';


class TokenHandler {
    static TOKEN_COOKIE_NAME: string = "TOKEN"
    static TOKEN_EXPIRY_TIMESTAMP: string = "TOKEN_EXPIRY_TIMESTAMP"
    static REFRESH_TOKEN_COOKIE_NAME: string = "REFRESH_TOKEN"
    static TOKEN_EXPIRY_SAFETY_BUFFER: number = 0.75
    static SECURE_TOKEN_SETTING: any = {secure: true, sameSite: 'None'}
    loginDetails: any;
    cookieHandler: CookieHandler;
    clock: Clock;

    constructor(loginDetails: any, clock: Clock, cookieHandler: CookieHandler) {
        this.loginDetails = loginDetails
        this.cookieHandler = cookieHandler
        this.clock = clock
    }

    async getValidToken() {
        if (this.isTokenValid()) {
            return this.getToken()
        }
        if (await this.refreshToken()) {
            return this.getToken()
        }
        return null
    }

    async refreshToken() {
        let refresh_token = this.getTokenKey(TokenHandler.REFRESH_TOKEN_COOKIE_NAME)
        if (!refresh_token) {
            return false 
        }
        try {
            let token = await this.refreshedTokenRequest(refresh_token)
            this.saveTokenDetails(token, this.clock.get_current_timestamp_in_millis())
        } catch (error) {
            return false
        }
        return true
    }

    async refreshedTokenRequest(refreshToken: string) {
        const params = new URLSearchParams();
        params.append('grant_type', 'refresh_token');
        params.append('refresh_token', refreshToken);
        return await this.tokenRequest(params)
    }

    async codeTokenRequest(code: string) {
        const params = new URLSearchParams();
        params.append('grant_type', 'authorization_code');
        params.append('code', code);
        return await this.tokenRequest(params)
    }

    /*  Token Request Docs
     *  https://docs.aws.amazon.com/cognito/latest/developerguide/token-endpoint.html
     */
    async tokenRequest(params: URLSearchParams) {
        params.append('client_id', this.loginDetails["CLIENT_ID"]);
        params.append('redirect_uri', this.loginDetails["REDIRECT_URI"]);

        let response = await fetch(this.loginDetails["AUTH_URL"], {
            method: 'POST',
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: params
        })

        if (!response.ok) {
            throw new Error("Failed to retrieve token")
        }

        let token = await response.json()
        return token
    }

    getToken() {
        return this.getTokenKey(TokenHandler.TOKEN_COOKIE_NAME)
    }

    getTokenKey(key: string) {
        let value = this.cookieHandler.getCookie(key)
        return value
    }

    saveTokenDetails(token: any, currentTimestampUTC: number) {
        let expiryTimestamp = this.computeExpiryTimestamp(token["expires_in"], currentTimestampUTC)
        let expiryDate = new Date(expiryTimestamp)
        this.cookieHandler.saveCookie(TokenHandler.TOKEN_COOKIE_NAME, token["id_token"], expiryDate)
        this.cookieHandler.saveCookie(TokenHandler.TOKEN_EXPIRY_TIMESTAMP, expiryTimestamp.toString(), expiryDate)
        if ("refresh_token" in token) {
            this.cookieHandler.saveCookie(TokenHandler.REFRESH_TOKEN_COOKIE_NAME, token["refresh_token"], expiryDate)
        }
    }

    deleteTokenDetails() {
        this.cookieHandler.deleteCookie(TokenHandler.TOKEN_COOKIE_NAME)
        this.cookieHandler.deleteCookie(TokenHandler.REFRESH_TOKEN_COOKIE_NAME)
        this.cookieHandler.deleteCookie(TokenHandler.TOKEN_EXPIRY_TIMESTAMP)
    }

    computeExpiryTimestamp(secondsToExpiry: number, currentTimestampUTC: number) {
        return currentTimestampUTC + TokenHandler.TOKEN_EXPIRY_SAFETY_BUFFER * secondsToExpiry * 1000
    }

    isTokenValid() {
        let token = this.getToken()
        if (token == null || this.isTokenExpired()) {
            return false
        }
        return true
    }

    isTokenExpired() {
        let tokenExpiryTimestamp: any = this.getTokenKey(TokenHandler.TOKEN_EXPIRY_TIMESTAMP)
        if (!tokenExpiryTimestamp) {
            return true
        }
        return this.clock.get_current_timestamp_in_millis() >= parseInt(tokenExpiryTimestamp)
    }
}

export default TokenHandler
