import UserInputError from "src/validation/exception_messages/UserInputError";
import ServerError from "src/validation/exception_messages/ServerError";
import AuthenticationError from "src/validation/exception_messages/AuthenticationError";
import ThrottleLimitExceededError from "./exception_messages/ThrottleLimitExceededError";
import QuotaLimitExceededError from "./exception_messages/QuotaLimitExceededError";

class Validator {

    constructor() {
    }

    validateComparableHomesInput(input: any) {
        let fields = ["address", "country", "county", "town"]
        let fieldLabels = ["Street Address", "Country", "County", "Town"]
        for (let i=0; i<fields.length; i++){
            if (input[fields[i]].length <= 0) {
                throw new UserInputError("A value must be specified for the '" + fieldLabels[i] + "' field.")
            }
        }
    }
    validateComparableHomesOutput(output: any) {
        if (!("comparable_homes" in output)) {
            throw new Error("Error retrieving API outputs")
        }
        if (output["comparable_homes"].length <= 0) {
            throw new UserInputError("Sorry, no comparable homes  were found for the input address.")
        }
    }

    validateValuateInput(input: any) {
        if (input["comparison_valuations"].length <= 0) {
            throw new UserInputError("There are no comparable valuations available to compute a valuation of the home.")
        }
    }

    validateValuateOutput(output: any) {
        if (!("longterm_valuation" in output)
            || !("current_valuation" in output["longterm_valuation"])
            || !("value" in output["longterm_valuation"]["current_valuation"])
            || !("future_valuation" in output["longterm_valuation"])
            || !("value" in output["longterm_valuation"]["future_valuation"])
            )
            {
            throw new Error("Error retrieving API outputs")
        }
    }

    async validateResponse(response: Response) {
        if (response.ok) {
            return
        }
        let content: any = await this.getErrorResponseContent(response)
        let message: any = this.getErrorMessage(content)
        let type: any = this.getErrorType(content)

        if (response.status == 429) {
            if ("quota_limit_exception" == type) {
                throw new QuotaLimitExceededError(message)
            }
            throw new ThrottleLimitExceededError(message)
        }
        if (response.status == 401 || response.status == 403) {
            throw new AuthenticationError(message)
        }
        if (400 <= response.status && response.status < 500) {
            throw new UserInputError(message);
        }
        if (500 <= response.status) {
            throw new ServerError(message);
        }
        throw new Error(message);
    }

    getErrorMessage(content: any) {
        try {
            if ("error" in content) {
                return content["error"]
            }
        } catch {}
        return content
    }

    getErrorType(content: any) {
        try {
            if ("type" in content) {
                return content["type"]
            }
        } catch {}
        return null
    }

    async getErrorResponseContent(response: Response) {
        if (!this.isJsonResponse(response)) {
            return await response.text()
        }
        let content = await response.json()
        return content
    }

    isJsonResponse(response: Response) {
        return response.headers.has("Content-Type") && response.headers.get("Content-Type") == "application/json"
    }

}

export default Validator
