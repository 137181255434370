import {Component} from "react"


class WebsiteName extends Component<any> {
    render() {
        return (
            <div className="website-name-container" >
                <svg width="100%" height="100%" className="website-name">
                    <text x="50%" y="50%" alignmentBaseline="middle" textAnchor="middle" className="major-mono-display-regular">Valterrain.com</text>
                </svg>
            </div>
        )
    }
}

export default WebsiteName
