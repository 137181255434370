import StateSavingChildComponent from "src/components/base/StateSavingChildComponent";
import MainForm from "src/components/house_valuation/house_valuation_inputs/forms/MainForm"
import HouseConditionForm from "src/components/house_valuation/house_valuation_inputs/forms/HouseConditionForm"


class HouseValuationInputsForm extends StateSavingChildComponent {

    render() {
        let advancedOptions = this.getState("advancedOptions")
        return (
            <form onSubmit={this.handleSumbit}>
                <MainForm parent={this.parent}/>
                {advancedOptions && <HouseConditionForm parent={this.parent}/>}
            </form>
        )
    }

    handleSumbit(event: any) {
        event.preventDefault();
        return false;
    }
}

export default HouseValuationInputsForm