import Clock from "src/general/Clock"
import CookieHandler from "src/components/login/CookieHandler"
import StateSavingComponent from "../base/StateSavingComponent"


class StateHandler {
    states: any = {}
    statesOfInterest = new Set([
        "country",
        "county",
        "town",
        "street-address",
        "current-house-condition"
    ])
    cookieHandler: CookieHandler
    clock: Clock
    restoredStates: boolean
    static STATE_PREFIX: string = "VALTERRAIN_STATE_"
    static EXPIRATION_TIME_IN_MILLIS: number = 5 * 60 * 1000

    constructor(cookieHandler: CookieHandler, clock: Clock) {
        this.cookieHandler = cookieHandler
        this.clock = clock
        this.restoredStates = false
    }

    setStates(states: any) {
        for (let [key, value] of Object.entries(states)) {
            if (this.statesOfInterest.has(key)) {
                this.states[key] = value
            }
        }
    }

    getStates() {
        return this.states
    }

    storeStatesPreLogin() {
        let expiryTimestamp = this.clock.get_current_timestamp_in_millis() + StateHandler.EXPIRATION_TIME_IN_MILLIS
        for (let [key, value] of Object.entries(this.states)) {
            if (typeof(value) == "string") {
                this.cookieHandler.saveCookie(StateHandler.STATE_PREFIX + key, value, expiryTimestamp)
            }
        }
    }

    restoreStatesPostLogin(component: StateSavingComponent) {
        if (this.restoredStates) {
            return
        }
        for (let key of Object.keys(component.states)) {
            if (this.statesOfInterest.has(key)) {
                let value = this.cookieHandler.getCookie(StateHandler.STATE_PREFIX + key)
                if (value) {
                    component.assignState(key, value)
                    this.cookieHandler.deleteCookie(StateHandler.STATE_PREFIX + key)
                }
            }
        }
        this.restoredStates = true
        return 
    }
}

export default StateHandler
