import { head } from "lodash";
import Validator from "src/validation/Validator"

class APIRequester {
    validator: Validator

    constructor(validator: Validator) {
        this.validator = validator;
    }

    async post(url: string, input: any, extra_headers: any = null) {
        let headers = {'Content-Type': 'application/json'}
        if (extra_headers) {
            headers = Object.assign({}, headers, extra_headers)
        }

        const response: Response = await fetch(
            url, 
            {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(input) 
            }
        )
        await this.validator.validateResponse(response)
        return await response.json()
    }
}

export default APIRequester
