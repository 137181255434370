import {Component} from "react"

class Logo extends Component<any> {

    drawCentralTriangle(xCordinate: number, yCoordinate: number, className: string) {

        let points = xCordinate + ",50 50,50 50," + yCoordinate
        return (
            <polygon points={points} className={className}/>
        )
    }

    coordinateToDisplacement(coordinate: number) {
        return coordinate - 50
    }

    displacementToCoordinate(displacement: number) {
        return displacement + 50
    }

    getSign(value: number) {
        if (value < 0) {
            return -1
        }
        return 1
    }

    drawShadedTriangle(startXCoordinate: number, startYCoordinate: number, xDifferential: number, className: string) {
        let shadedTriangle = []
        let xCoordinate = startXCoordinate
        let yCoordinate = startYCoordinate

        let xDisplacement = this.coordinateToDisplacement(startXCoordinate)
        let yDisplacement = this.coordinateToDisplacement(startYCoordinate)

        let xSign = this.getSign(xDisplacement)
        let ySign = this.getSign(yDisplacement)

        let absXDisplacement = Math.abs(xDisplacement)
        let absYDisplacement = Math.abs(yDisplacement)

        let ratio = absYDisplacement / absXDisplacement

        while(absXDisplacement > 0) {
            xDisplacement = xSign * absXDisplacement
            yDisplacement = ySign * absYDisplacement

            xCoordinate = this.displacementToCoordinate(xDisplacement)
            yCoordinate = this.displacementToCoordinate(yDisplacement)

            shadedTriangle.push(this.drawCentralTriangle(xCoordinate, yCoordinate, className))

            absXDisplacement -= xDifferential
            absYDisplacement = absXDisplacement * ratio
        }

        return shadedTriangle
    }

    render() {
        return (
            <div className="logo-container">
                <svg className="logo" viewBox="0,0,100,100">
                    <rect width="100%" height="100%" rx="50" ry="50" className="logo-background" />
                    {this.drawCentralTriangle(20, 0, "logo-roof")}
                    {this.drawCentralTriangle(20, 30, "logo-wall")}
                    {this.drawCentralTriangle(80, 10, "logo-wall")}
                    {this.drawCentralTriangle(20, 70, "logo-pavement")}
                    <circle cx="55" cy="42.5" r="0.4" className="logo-door-knob"/>
                    <rect x="55" y="35" width="8" height="30" className="logo-door"/>
                    {this.drawCentralTriangle(80, 100, "logo-grass")}
                </svg>
            </div>
        )
    }
}

export default Logo
