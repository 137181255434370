import {Component} from "react"

class Footer extends Component<any> {
    render() {
        return (
            <footer id="footer" className="footer">
                <div className="disclaimer-header">Usage Guidance</div>
                <div className="disclaimer" id="disclaimer">
                    The house valuation estimates provided are for informational purposes only and should not be considered as financial or professional advice.
                </div>
            </footer>
        )
    }
}

export default Footer
