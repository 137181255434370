import Cookies from 'js-cookie';
import CookieHandler from 'src/components/login/CookieHandler';


class PersistentCookieHandler implements CookieHandler {
    useHttpsProtocol: boolean;
    static SECURE_COOKIE_SETTING: any = {secure: true, sameSite: 'None'}

    constructor(useHttpsPrototcol: boolean) {
        this.useHttpsProtocol = useHttpsPrototcol
    }

    saveCookie(key: string, value: string, expireyDate: any) {
        Cookies.set(key, value, this.getCookieSettings(expireyDate))
    }

    getCookie(key: string): any {
        let value = Cookies.get(key)
        return value
    }

    getCookieSettings(expireyDate: any) {
        let tokenSettings: any = {} 
        if (this.useHttpsProtocol) {
            tokenSettings = {...tokenSettings, ...PersistentCookieHandler.SECURE_COOKIE_SETTING}
        }
        if (expireyDate) {
            tokenSettings["expires"] = expireyDate
        }
        return tokenSettings
    }

    deleteCookie(key: string) {
        Cookies.remove(key)
    }
}

export default PersistentCookieHandler
