import StateSavingChildComponent from "src/components/base/StateSavingChildComponent";
import ValuationResultBellCurveWidget from "src/components/custom/bell_curve/ValuationResultBellCurveWidget"


class ValuationResultWidget extends StateSavingChildComponent {

    render() {
        return (
            <div className="longterm-valuation widget">
                <ValuationResultBellCurveWidget parent={this.parent}/>
            </div>
        )
    }
}

export default ValuationResultWidget
