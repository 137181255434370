
let DEPLOYMENT_ENVIRONMENT_CONFIG: any = {
    "DEFAULT": {
        "BACKEND_BASE_API_URL": "https://api.valterrain.com",
        "LOGIN_DETAILS": {
            "AUTH_URL": "https://auth.valterrain.com/oauth2/token",
            "BASE_URL": "https://auth.valterrain.com/login",
            "CLIENT_ID": "1hpvm7nlcosqfbhrvfur037k4s",
            "REDIRECT_URI": "https://www.valterrain.com/",
            "LOGIN_URL": "https://auth.valterrain.com/login?client_id=1hpvm7nlcosqfbhrvfur037k4s&response_type=code&scope=email+openid+phone&redirect_uri=https://www.valterrain.com/",
            "HOME_URI": "https://www.valterrain.com",
        },
        "IS_HTTPS": true
    },
    "DEV": {
        "BACKEND_BASE_API_URL": "http://localhost:5000",
        "LOGIN_DETAILS": {
            "AUTH_URL": "https://auth.valterrain.com/oauth2/token",
            "BASE_URL": "https://auth.valterrain.com/login",
            "CLIENT_ID": "1hpvm7nlcosqfbhrvfur037k4s",
            "REDIRECT_URI": "http://localhost:3000/",
            "LOGIN_URL": "https://auth.valterrain.com/login?client_id=1hpvm7nlcosqfbhrvfur037k4s&response_type=code&scope=email+openid+phone&redirect_uri=http://localhost:3000/",
            "HOME_URI": "http://localhost:3000/",
        },
        "IS_HTTPS": false
    },
    "PREPROD": {
        "BACKEND_BASE_API_URL": "https://api.valterrain.com",
        "LOGIN_DETAILS": {
            "AUTH_URL": "https://auth.valterrain.com/oauth2/token",
            "BASE_URL": "https://auth.valterrain.com/login",
            "CLIENT_ID": "1hpvm7nlcosqfbhrvfur037k4s",
            "REDIRECT_URI": "http://localhost:3000/",
            "LOGIN_URL": "https://auth.valterrain.com/login?client_id=1hpvm7nlcosqfbhrvfur037k4s&response_type=code&scope=email+openid+phone&redirect_uri=http://localhost:3000/",
            "HOME_URI": "http://localhost:3000/",
        },
        "IS_HTTPS": false
    },
    "PROD": {
    }
}

export default DEPLOYMENT_ENVIRONMENT_CONFIG
