import React, {Component} from "react"

import StateSavingComponent from "src/components/base/StateSavingComponent";


class StateSavingChildComponent extends Component<any> {
    parent: StateSavingComponent;

    constructor(props: any) {
        super(props);
        this.parent = props.parent
        this.handleChange = this.handleChange.bind(this);
        this.assignState = this.assignState.bind(this);
        this.valuate = this.valuate.bind(this);
        this.newValuation = this.newValuation.bind(this);
    }

    handleChange(event: React.ChangeEvent<any>) {
        this.parent.handleChange(event)
    }

    getState(value: string) {
        return this.parent.getState(value)
    }

    assignState(key: string, value: any){
        this.parent.assignState(key, value)
    }

    async valuate() {
        return this.parent.valuate()
    }

    newValuation() {
        return this.parent.newValuation()
    }
}

export default StateSavingChildComponent