import TokenHandler from "src/components/login/TokenHandler"
import Clock from "src/general/Clock";


class LoginHelper {
    expiredCodes: Set<String> = new Set()
    loginDetails: any;
    tokenHandler: TokenHandler
    code: any;
    clock: Clock;

    constructor(loginDetails: any, tokenHandler: TokenHandler, clock: Clock, code: any = null) {
        this.loginDetails = loginDetails
        this.tokenHandler = tokenHandler
        this.code = code
        this.clock = clock
    }

    setCode(code: string) {
        this.code = code
    }

    async getValidToken() {
        return await this.tokenHandler.getValidToken()
    }

    logout() {
        this.tokenHandler.deleteTokenDetails()
    }

    async login() {
        if (await this.already_logged_in()) {
            this.redirectToHome()
            return
        }

        let code: any = this.code

        if (this.shouldLogin(code)) {
            this.redirectToLoginSite()
            return
        }

        if (!this.isCodeValid(code)) {
            return
        }
        this.expiredCodes.add(code)
        let token = await this.tokenHandler.codeTokenRequest(code)
        this.tokenHandler.saveTokenDetails(token, this.clock.get_current_timestamp_in_millis())
        this.code = null
        this.redirectToHome()
    }

    static getLoginCode() {
        let url = window.location.href;
        let urlObj = new URL(url);
        return urlObj.searchParams.get('code');
    }

    async already_logged_in() {
        return Boolean(await this.tokenHandler.getValidToken())
    }

    shouldLogin(code: any) {
        return !code 
    }

    redirectToLoginSite() {
        window.location.href = this.loginDetails["LOGIN_URL"]
    }

    isCodeValid(code: string) {
        return !this.expiredCodes.has(code)
    }
    
    redirectToHome() {
        window.location.href = this.loginDetails["HOME_URI"]
    }
}

export default LoginHelper
