import BellCurveWidget from "src/components/custom/bell_curve/BellCurveWidget"
import BellCurve from "src/components/custom/bell_curve/BellCurve"


class BellCurveSlider extends BellCurveWidget {
    sliderLabel : string;
    sliderValue : string;

    constructor(props: any) {
        super(props)
        this.BELL_CURVE_STD = 20
        this.parent = props.parent
        this.sliderLabel = props.sliderLabel
        this.sliderValue = props.sliderValue
        this.stepSize = 2
        this.maxWidthPercentage = 82.5 
        this.maxHeightPercentage = 90
        this.bellCurve = new BellCurve(this.BELL_CURVE_MEAN, this.BELL_CURVE_STD)
        this.scalingFactor = this.computeScalingFactor()
    }

    getBellCurve() {
        let sliderPostionValue = this.getState(this.sliderValue)
        
        let bellCurve = (
            <svg className="bell-curve-slider">
                {this.getBellCurveShapeLines()}
                <circle className="bell-curve-ticker" cx={this.getBellCurveX(sliderPostionValue)} cy={this.getBellCurveY(sliderPostionValue)} r="3"/>
                <text className="bell-curve-slider-label" x={this.getBellCurveX(50)} y="95%" textAnchor="middle">{sliderPostionValue}%</text>
            </svg>
        )
        return bellCurve
    }

    render() {
        let sliderPostionValue = this.getState(this.sliderValue)
        let id = "valuation-form-" + this.sliderValue
        return (
            <div className="container bell-curve-slider-container">
                <div className="partition">
                    <div className="partition"> 
                        {this.getBellCurve()}
                    </div>
                    <div className="partition"> 
                        <input className="condition-range" id={id} name={this.sliderValue} type="range" min="0" max="100" defaultValue={sliderPostionValue} step="2" onChange={this.handleChange}/>
                    </div>
                    <div className="partition"> 
                        <div className="bell-curve-label-container">
                            <label className="bell-curve-label">{this.sliderLabel}</label>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BellCurveSlider