import React from "react";
import StateSavingChildComponent from "src/components/base/StateSavingChildComponent";
import ValuationStateHelper from "src/utils/ValuationStateHelper";
import Format from "src/utils/Format";


class ComparableHomesWidget extends StateSavingChildComponent {
    constructor(props: any) {
        super(props)
        this.updateComparableHomeFieldState = this.updateComparableHomeFieldState.bind(this)
        this.removeComparableHome = this.removeComparableHome.bind(this)
    }

    updateComparableHomeFieldState(event: React.ChangeEvent<any>) {
        let {type, name, value} = event.target
        let dataId = event.target.getAttribute("data-id")
        if (type == "number") {
            value = Number(value)
        }
        ValuationStateHelper.updateComparableHomeField(this, dataId, name, value)
    }

    removeComparableHome(event: React.MouseEvent<any>) {
        let dataId = event.currentTarget.getAttribute("data-id")
        ValuationStateHelper.removeComparableHome(this, dataId);
    }

    getComparableHomeDivs() {
        let comparableHomeDict = this.getState("comparableHomes");
        let comparableHomeIds = Object.keys(comparableHomeDict)
        let comparableHomeDivs = []
        let index = 0

        for (let id of comparableHomeIds) {
            comparableHomeDivs.push(this.getComparableHomeDiv(id, comparableHomeDict[id]))
            index++
        }
        return comparableHomeDivs
    }


    getComparableHomeDiv(identifier: any, comparableHome: any) {
        let prefix = "comparable-homes"
        let suffix = "field-" + identifier

        let comparableHomeId = prefix + "-" + suffix
        let valueId = prefix + "-value-" + suffix
        let salePriceId = prefix + "-sale_price-" + suffix
        let saleDateId = prefix + "-sale_date-" + suffix
        let addressId = prefix + "-address-" + suffix
        let linkId = prefix + "-previous_house_listing_link-" + suffix
        let removalButtonId = prefix + "-remove-" + suffix
        let isPreviousHouseListingLinkPresent = "previous_house_listing_link" in comparableHome

        return (
            <tr id={comparableHomeId}>
                <td className="element" id={valueId}>{Format.getFormattedCurrency(comparableHome["value"])}</td>
                <td className="element" id={salePriceId}>{Format.getFormattedCurrency(comparableHome["sale_price"])}</td>
                <td className="element" id={saleDateId}>{comparableHome["sale_date"]}</td>
                {isPreviousHouseListingLinkPresent && <td className="element" id={addressId}><a id={linkId} href={comparableHome["previous_house_listing_link"]}>{comparableHome["address"]}</a></td>}
                {!isPreviousHouseListingLinkPresent && <td className="element" id={addressId}>{comparableHome["address"]}</td>}
                <td className="x-button-container"><button className="x-button" data-id={identifier} id={removalButtonId} onClick={this.removeComparableHome}>x</button></td>
            </tr>
        )
    }


    render() {
        let comparableHomes = this.getComparableHomeDivs()
        return (
            <div className="comparable-homes">
                <table>
                    <thead>
                        <tr>
                            <th>Current Value</th>
                            <th>Sale Price</th>
                            <th>Sale Date</th>
                            <th>Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        {comparableHomes}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default ComparableHomesWidget
