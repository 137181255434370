import StateSavingChildComponent from "src/components/base/StateSavingChildComponent";
import ComparableHomesWidget from "src/components/house_valuation/house_valuation_results/widgets/ComparableHomesWidget";
import ValuationResultWidget from "src/components/house_valuation/house_valuation_results/widgets/ValuationResultWidget";
import UpdateValuationForm from "./forms/UpdateValuationForm";


class HouseValuationResultsPage extends StateSavingChildComponent {
    render() {
        let streetAddress: string = this.getState("street-address")

        return (
            <div>
                <UpdateValuationForm parent={this.parent}/>
                <div className="property-title title">{streetAddress.toLowerCase()}</div>
                <div className="valuation-container">
                    <div className="valuation-visual-partition">
                        <div className="title valuation-title">Valuation</div>
                        <ValuationResultWidget parent={this.parent}/>
                    </div>
                    <div className="valuation-data-partition">
                        <div className="comparable-homes-container">
                            <div className="title comparable-homes-title">comparable homes</div>
                            <ComparableHomesWidget parent={this.parent}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HouseValuationResultsPage
