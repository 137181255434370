
class PathRouter {
static HOME_PATH: string = "/"
    static LOGIN_PATH: string = "/login"
ALLOWED_PATHS = new Set([PathRouter.HOME_PATH, PathRouter.LOGIN_PATH])

    PathRouter() {}

    getRoute(current_path: string) {
        let path: any = this.getPath()
        if (this.ALLOWED_PATHS.has(path)) {
            return path
        }
        return current_path
    }

    getPath() {
        let url = window.location.href;
        let urlObj = new URL(url);
        return urlObj.searchParams.get('path');
    }
}

export default PathRouter
