import ValuationAPIHelper from "src/utils/ValuationAPIHelper"
import APIRequester from "src/api/APIRequester"
import Validator from "src/validation/Validator"
import DeploymentEnvironmentConfigOperator from "src/config/DeploymentEnvironmentConfigOperator"
import DEPLOYMENT_ENVIRONMENT_CONFIG from "src/config/DeploymentEnvironmentConfig"
import EnvironmentVariableProvider from "src/config/EnvironmentVariableProvider"
import LoginHelper from "src/components/login/LoginHelper"
import TokenHandler from "src/components/login/TokenHandler"
import PathRouter from "src/navigation/PathRouter"
import ErrorHandler from "src/components/errors/ErrorHandler"
import Loader from "src/components/general/Loading"
import StateHandler from "src/components/login/StateHandler"
import Clock from "src/general/Clock"
import PersistentCookieHandler from "src/components/login/PersistentCookieHandler"
import CookieHandler from "src/components/login/CookieHandler"


class Config {
    deploymentEnvironmentConfig: DeploymentEnvironmentConfigOperator
    environmentVariableProvider: EnvironmentVariableProvider

    constructor() {
        this.environmentVariableProvider = new EnvironmentVariableProvider()
        this.deploymentEnvironmentConfig = new DeploymentEnvironmentConfigOperator(DEPLOYMENT_ENVIRONMENT_CONFIG, this.environmentVariableProvider)
    }

    getPathRouter() {
        return new PathRouter()
    }

    getValuationAPIHelper() {
        return new ValuationAPIHelper(
            this.getAPIRequester(),
            this.deploymentEnvironmentConfig.get("BACKEND_BASE_API_URL"),
            this.getLoginHelper(),
            this.getStateHandler()
        )
    }

    getStateHandler() {
        return new StateHandler(
            this.getCookieHandler(), 
            this.getClock()
        )
    }

    getClock() {
        return new Clock()
    }

    getAPIRequester() {
        return new APIRequester(
            this.getValidator()
        )
    }

    getValidator() {
        return new Validator()
    }

    getLoginDetails(): string {
        return this.deploymentEnvironmentConfig.get("LOGIN_DETAILS")
    }

    isHTTPS(): boolean {
        return this.deploymentEnvironmentConfig.get("IS_HTTPS")
    }

    getLoginHelper(code: any = null): LoginHelper {
        return new LoginHelper(this.getLoginDetails(), this.getTokenHandler(), this.getClock(), code)
    }

    getTokenHandler(): TokenHandler {
        return new TokenHandler(
            this.getLoginDetails(), 
            this.getClock(),
            this.getCookieHandler()
        )
    }

    getCookieHandler(): CookieHandler {
        return new PersistentCookieHandler(this.isHTTPS())
    }

    getErrorHandler(): ErrorHandler {
        return new ErrorHandler()
    }

    getLoader(): Loader {
        return new Loader()
    }
}

export default Config
