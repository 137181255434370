import StateSavingChildComponent from "src/components/base/StateSavingChildComponent";
import HouseConditionForm from "src/components/house_valuation/house_valuation_inputs/forms/HouseConditionForm"
import MainUpdateForm from "src/components/house_valuation/house_valuation_results/forms/MainUpdateForm"


class UpdateValuationForm extends StateSavingChildComponent {
    render() {
        let advancedOptions = this.getState("advancedOptions")
        return (
            <form onSubmit={this.handleSubmit}>
                <MainUpdateForm parent={this.parent}/>
                {advancedOptions && <HouseConditionForm parent={this.parent}/>}
            </form>
        )
    }

    handleSubmit(event: any) {
        event.preventDefault();
        return false;
    }
}


export default UpdateValuationForm
